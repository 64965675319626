"use strict";

document.addEventListener("click", function (e) {
  if (e.target.closest("body") !== null) {
    hide_videos();
  }
});

///////////////////////////////////////
// Modal window

const modal = document.querySelector(".modal");
const overlay = document.querySelector(".overlay");
const btnCloseModal = document.querySelector(".btn--close-modal");
const btnsOpenModal = document.querySelectorAll(".btn--show-modal");
const operations = document.querySelector(".operations");

const openModal = function () {
  modal.classList.remove("hidden");
  overlay.classList.remove("hidden");
};

const closeModal = function () {
  modal.classList.add("hidden");
  overlay.classList.add("hidden");
};

btnsOpenModal.forEach((btns) => btns.addEventListener("click", openModal));

btnCloseModal.addEventListener("click", closeModal);
overlay.addEventListener("click", closeModal);

document.addEventListener("keydown", function (e) {
  if (e.key === "Escape" && !modal.classList.contains("hidden")) {
    closeModal();
  }
});

const header = document.querySelector(".header");

const allSections = document.querySelectorAll(".section"); // NodeList

document.getElementById("section--1");
const allButtons = document.getElementsByTagName("button"); // HTMLCollection

const message = document.createElement("div"); // Created, but not inserted
// message.classList.add("cookie-message");

// message.innerHTML =
//   'We use cookies for better content <button class="btn btn--close--cookie">Got it!</button>';

// header.before(message);
header.append(message);

///////////////////////////////////////
// Button scrolling
const btnScrollTo = document.querySelector(".btn--scroll-to");
const section1 = document.querySelector("#section--1");

btnScrollTo.addEventListener("click", function (e) {
  const s1coords = section1.getBoundingClientRect();
  section1.scrollIntoView({ behavior: "smooth" });
});

// rgb(255, 255, 255);
const randomInt = (min, max) =>
  Math.floor(Math.random() * (max - min + 1) + min);
const randomColor = () =>
  `rgb(${randomInt(0, 255)},${randomInt(0, 255)},${randomInt(0, 255)})`;

const thing = document.querySelectorAll(".nav__link");

document.querySelectorAll(".nav__link").forEach(function (el) {
  addEventListener("click", function (e) {
    e.preventDefault();
  });
});

document.querySelector(".nav__links").addEventListener("click", function (e) {
  if (
    e.target.classList.contains("nav__link") &&
    !e.target.classList.contains("btn")
  ) {
    const id = e.target.getAttribute("href");
    document.querySelector(id).scrollIntoView({ behavior: "smooth" });
  }
});

const h1 = document.querySelector("h1");

const [...children_arr] = h1.parentElement.children;
children_arr.forEach(function (el) {
  if (el !== h1) el.style.transform = "scale(1)";
});

///////////////////////////////////////////////////////////////////////////

const makeActive = function () {
  document
    .querySelector(".operations__tab-container")
    .addEventListener("click", function (e) {
      const button = e.target.closest("button");
      if (!button) return;

      // Clear other tabs
      [...operations.children].forEach(function (el) {
        el.classList.remove("operations__content--active");
      });

      const [...children] = this.children;
      children.forEach(function (el) {
        el.classList.remove("operations__tab--active"); // deactivate all children

        // Activate tab
        button.classList.add("operations__tab--active");

        // Activate matching tab content
        const newActive = document.querySelector(
          `.operations__content--${button.dataset.tab}`
        );
        newActive.classList.add("operations__content--active");
      });
    });
};

makeActive();

const nav = document.querySelector(".nav");

const handleEvent = function (e) {
  if (e.target.classList.contains("nav__link")) {
    const link = e.target;
    const siblings = link.closest(".nav").querySelectorAll(".nav__link");
    siblings.forEach((el) => {
      if (el !== link) el.style.opacity = this.opacity;
    });
  }
};

nav.addEventListener("mouseover", handleEvent.bind({ opacity: 0.5 }));

nav.addEventListener("mouseout", handleEvent.bind({ opacity: 1 }));

/////////////////////////////////////////////////////////////////////////sticky navigation

const sect1 = document.querySelector("#section--1");
const rect = sect1.getBoundingClientRect();

const navHeight = nav.getBoundingClientRect().height + 1;

const stickyNav = function (entries) {
  const [entry] = entries;

  if (!entry.isIntersecting) nav.classList.add("sticky");
  else nav.classList.remove("sticky");
};

const headerObserver = new IntersectionObserver(stickyNav, {
  root: null,
  threshold: 0,
  rootMargin: `-${navHeight}px`,
});

headerObserver.observe(header);

////////////////////////////////////////////////// Reveal section elements

const targetSections = document.querySelectorAll(".section");

const revealSection = function (entries) {
  const [entry] = entries;

  if (!entry.isIntersecting) return;

  entry.target.classList.remove("section--hidden");
};

const obsSection = new IntersectionObserver(revealSection, {
  root: null,
  threshold: 0.2,
});

targetSections.forEach(function (section) {
  const width = window.innerWidth;

  if (width > 1024) {
    section.classList.add("section--hidden"); // Apply desktop layout styles
  } else {
    const operations_section = operations.parentElement;
    operations_section.classList.add("section--hidden");
  }
  obsSection.observe(section);
});

////////////////////////////////////////////////// Lazy Load Images

const allTheSections = document.querySelectorAll("img[data-src]");

const revealImg = function (entries, observer) {
  const [entry] = entries;

  if (!entry.isIntersecting) return;

  // Remove blur after high-quality is loaded
  entry.target.classList.remove("lazy-img");

  // Swap lazy image for high-quality
  entry.target.classList.remove("section--hidden");
  observer.unobserve(entry.target);
};

const sectionObserver = new IntersectionObserver(revealImg, {
  root: null,
  threshold: 0.1,
  rootMargin: "0px",
});

allTheSections.forEach(function (section) {
  sectionObserver.observe(section);
});

////////////////////////////////////////////////// Build a slider

const slides = function () {
  const slides = document.querySelectorAll(".slide");
  const sliderBtnRt = document.querySelector(".slider__btn--right");
  const sliderBtnLt = document.querySelector(".slider__btn--left");

  let curSlide = 0;
  const maxSlide = slides.length - 1;
  const slider = document.querySelector(".slider");

  const goToSlide = function (curSlide) {
    slides.forEach(function (s, i) {
      s.style.transform = `translateX(${(i - curSlide) * 100}%)`;
    });
  };

  const nextSlide = function () {
    if (curSlide === maxSlide) {
      curSlide = 0;
    } else {
      curSlide++;
    }
    goToSlide(curSlide);
    createDots();
    activateDot(curSlide);
  };

  const prevSlide = function () {
    if (curSlide === 0) {
      curSlide = maxSlide;
    } else {
      curSlide--;
    }
    goToSlide(curSlide);
    createDots();
    activateDot(curSlide);
  };

  // Dynamic State
  sliderBtnRt.addEventListener("click", function () {
    nextSlide();
  });

  document.addEventListener("keydown", function (e) {
    if (e.key === "ArrowRight") {
      nextSlide();
    }
    if (e.key == "ArrowLeft") {
      prevSlide();
    }
  });

  sliderBtnLt.addEventListener("click", function () {
    prevSlide();
  });

  // add dots
  const dotsContainer = document.querySelector(".dots");

  const createDots = function () {
    dotsContainer.innerHTML = "";
    slides.forEach(function (_, i) {
      dotsContainer.insertAdjacentHTML(
        "beforeend",
        `<button class="dots__dot" data-slide=${i}></button>`
      );
    });
  };

  const activateDot = function (slide) {
    document
      .querySelectorAll(".dots")
      .forEach((dot) => dot.classList.remove("dots__dot--active")); // Clear active class
    document
      .querySelector(`.dots__dot[data-slide="${slide}"]`)
      .classList.add("dots__dot--active"); // Add active class
  };

  // Initial state
  const init = function () {
    goToSlide(0);
    createDots();
    activateDot(0);
  };
  init();

  dotsContainer.addEventListener("click", function (e) {
    if (e.target.classList.contains("dots__dot")) {
      const { slide } = e.target.dataset;
      curSlide = parseInt(slide);
      goToSlide(curSlide);
      createDots();
      activateDot(curSlide);
    }
  });
};
// slides();

////////////////////////////////////////////////// Dynamic layout
function adjustLayout() {
  const width = window.innerWidth;

  if (width <= 480) {
    // Apply mobile layout styles
  } else if (width <= 768) {
    // Apply tablet layout styles
  } else {
    // Apply desktop layout styles
  }
}

window.addEventListener("resize", adjustLayout);
adjustLayout();
